import de from "../assets/de_new.json";
import deDict from "../assets/de.json";

import store from "./store";
const words = {
    "de": de,
}

const dicts = {
    "de": deDict
}
const startDate = new Date("2022-04-26").getTime();

export default {
    checkWord,
    getRandomWord,
    getTodayWord,
}

function checkWord(word, length) {
    return dicts[store.getLanguage()][String(length)].includes(word.toUpperCase()) || words[store.getLanguage()][String(length)].includes(word.toUpperCase());
}

function getRandomWord(length) {
    let s = String(length);
    let lang = store.getLanguage();
    let r = Math.floor(Math.random() * words[lang][s].length);
    r = r == words[lang][s].length ? r - 1 : r;
    return words[lang][s][r].toUpperCase();
}

function getTodayWord(date, length) {
    let idx = (new Date(date).getTime() - startDate) / (1000 * 3600 * 24);
    return words[store.getLanguage()][String(length)][idx];
}