<template>
  <div class="btn btn-primary"
       v-if="random && state != STATE.PLAYING"
       tabindex="0"
       @click="resetGame"
  >Neustart
  </div>
  <div class="guesses" ref="guessesContainer">
    <div 
      v-for="(guess, idx) in guesses" 
      :ref="'guess-'+idx"
      :key="'guess-'+idx"
    >
      <word-row
          :submitted="currentGuess > idx || ((state == 3 || state == 4) && idx < currentGuess)"
          :letters="guess"
          :word="randomWord"
          @set-cursor="setCursor"
          :error="currentGuess == idx && wordError"
          :cursor="currentGuess == idx ? cursor : -1"
      ></word-row>
    </div>
  </div>
  <div class="vkboard">
    <virtual-keyboard
        :wrong="wrong"
        :exact="exact"
        :contained="contained"
        @click-key="handleKeyboard"
        @click-del="deleteLetter"
        @click-enter="submitWord"
        :lang="language"
    ></virtual-keyboard>
  </div>
  <div class="modal fade" ref="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-dark text-white">
        <div class="modal-header">
          <h5 class="modal-title text-center" v-if="state == STATE.WON">Wort erraten!</h5>
          <h5 class="modal-title text-center" v-if="state == STATE.LOST">Bestimmt klappt's beim nächsten Mal!</h5>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <template v-if="state == STATE.WON">
            <p>Du hast das Wort in <b>{{ currentGuess == 1 ? "einem" : currentGuess }}</b> Versuch{{ currentGuess == 1 ? "" : "en" }} erraten!</p>
            <p>Aktuelle Serie: <span class="badge bg-primary">{{ currentStreak }}</span></p>
            <p>Höchste Serie: <span class="badge bg-primary">{{ highestStreak }}</span></p>
            <p v-if="!this.random">
              <button class="btn btn-secondary" @click="share">Teilen</button>
            </p>
          </template>
          <template v-else-if="state == STATE.LOST">
            <p>Leider konntest du das gesuchte Wort nicht erraten.</p>
            <p>Es war: <span tabindex="0"
                                   @keydown.enter="showWord = !showWord"
                                   @keydown.space="showWord = !showWord"
                                   @click="showWord = !showWord" class="btn btn-primary">
            <span v-show="showWord">{{ randomWord }}</span>
            <small v-show="!showWord">(AUFDECKEN)</small>
            </span>
            </p>
            <p>Aktuelle Serie: <span class="badge bg-primary">{{ currentStreak }}</span></p>
            <p>Höchste Serie: <span class="badge bg-primary">{{highestStreak}}</span></p>
            <p v-if="!this.random">
              <button class="btn btn-secondary" @click="share">Teilen</button>
            </p>
          </template>
          <template v-else>
            <p>Aktuelle Serie: <span class="badge bg-primary">{{ currentStreak }}</span></p>
            <p>Höchste Serie: <span class="badge bg-primary">{{highestStreak}}</span></p>
          </template>
          <stats ref="stats" :storeName="storeName"></stats>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import worder from "@/helper/worder";
import VirtualKeyboard from "@/components/VirtualKeyboard";
import WordRow from "@/components/WordRow";
import bootstrap from "@/assets/bootstrap.min.js";
import store from "@/helper/store";
import { guessesToEmoji } from "@/helper/emojinize";
import Stats from "@/components/Stats";
const STATE = {
  MENU: 1,
  PLAYING: 2,
  WON: 3,
  LOST: 4,
}

export default {
  name: 'Wordler',
  data: () => ({
    firstPlay: true,
    randomWord: "",
    wrong: [],
    exact: [],
    contained: [],
    guesses: [],
    currentGuess: 0,
    language: "de",
    wordError: false,
    currentStreak: 0,
    highestStreak: 0,
    showWord: false,
    state: STATE.MENU,
    STATE: STATE,
    scores: {},
    cursor: 0,
  }),
  props: {
    wordLength: {
      type: Number,
      default: 5,
    },
    random: {
      type: Boolean,
      default: false
    },
    storeName: {
      type: String,
      default: ""
    }
  },
  methods: {
    getTodaysWord() {
      this.randomWord = worder.getTodayWord(this.getTodayDate(), this.wordLength);
    },
    getRandomWord() {
      this.randomWord = worder.getRandomWord(this.wordLength);
    },
    resumeGame() {
      this.guesses = store.getCurrentGuesses(this.storeName);
      if(this.guesses[0].length != this.randomWord.length) {
        this.resetGame();
      } else {
        this.currentGuess = store.getCurrentGuess(this.storeName);
        this.state = store.getState(this.storeName);
        let idx = this.guesses[this.currentGuess].indexOf("");
        if(this.state !== STATE.PLAYING) {
          this.cursor = -1;
        } else {
          this.cursor = idx == -1 ? this.wordLength - 1 : idx;
        }
        if(this.state == STATE.MENU) {
          this.state = STATE.PLAYING;
          store.setState(this.state, this.storeName)
        }
        this.checkLetters();
        this.checkWin();
      }
    },
    setCursor(index) {
      if(this.state !== STATE.PLAYING) return;
      this.cursor = index;
    },
    resetGame() {
      if(this.random) {
        this.getRandomWord();
      }
      this.wrong = [];
      this.showWord = false;
      this.exact = [];
      this.contained = [];
      let length = this.randomWord.length;
      this.guesses = [
          new Array(length).fill(""),
          new Array(length).fill(""),
          new Array(length).fill(""),
          new Array(length).fill(""),
          new Array(length).fill(""),
          new Array(length).fill(""),
      ];
      this.currentGuess = 0;
      this.state = STATE.PLAYING;
      if(!this.random) {
        store.setCurrentGuesses(this.guesses, this.storeName);
        store.setCurrentGuess(this.currentGuess, this.storeName);
        store.setRoundDate(this.getTodayDate(), this.storeName);

        store.setState(this.state, this.storeName);
      }
    },
    handleKeyboard(key) {
      if(this.state == STATE.PLAYING) {
        let guess = this.guesses[this.currentGuess];
        if(this.cursor === guess.length) return;
        guess[this.cursor] = key;
        this.cursor++;
        this.guesses[this.currentGuess] = guess.slice(0);
        this.wordError = false;
        if(!this.random){
          store.setCurrentGuesses(this.guesses, this.storeName);
        }
      }
    },
    submitWord() {
      if(this.state == STATE.PLAYING) {
        this.wordError = false;
        let guess = this.guesses[this.currentGuess].join("");
        if (guess.length < this.randomWord.length) {
          this.wordError = true;
          window.navigator.vibrate([100, 50, 100]);
        } else {
          if (worder.checkWord(guess, this.wordLength)) {
            if (guess == this.randomWord) {
              this.wordGuessed();
              this.currentGuess++;
              this.addScore(this.currentGuess);
              if(!this.random){
                store.setCurrentGuess(this.currentGuess, this.storeName);
              }
              this.$refs.stats.updateScores();
              this.checkLetters();
              return;
            }
            this.currentGuess++;
            this.cursor = 0;
            if(!this.random){
              store.setCurrentGuess(this.currentGuess, this.storeName);
            }
            this.checkLetters();
            if (this.currentGuess == this.guesses.length) {
              if(!this.random){
                store.setCurrentGuess(this.currentGuess, this.storeName);
                store.setState(STATE.LOST, this.storeName);
              }
              this.addScore("X");
              this.$refs.stats.updateScores();
              this.failedToGuess();
              return;
            }
            this.scrollToNextGuess();
          } else {
            this.wordError = true;
            window.navigator.vibrate([100, 50, 100]);
          }
        }
      }
    },
    checkWin() {
      try {
      if(this.guesses[this.currentGuess - 1].join("") == this.randomWord) {
        this.state = STATE.WON;
        store.setState(this.state, this.storeName);
        this.showModal(true);
      }
      } catch(ex) {

      }
    },
    addScore(guess) {
      this.scores[String(guess)] = (this.scores[String(guess)] || 0) + 1;
      store.setScores(this.scores, this.storeName);
    },
    checkLetters() {
      let guesses = this.guesses.slice(0, this.currentGuess);
      this.wrong = guesses.flat().filter(l => !this.randomWord.includes(l));
      this.exact = guesses.reduce((arr, guess) => {
        return [...arr,
          this.randomWord
              .toUpperCase()
              .split("")
              .filter((l, i) => {
                return l == guess[i]
              })]
      }, []).flat();
      this.contained = guesses.reduce((arr, guess) => {
        return [...arr,
          guess.filter(l => {
            return this.randomWord.indexOf(l) !== -1 && !this.exact.includes(l)
          })
        ]
      }, []).flat();
    },
    deleteLetter() {
      if(this.state == STATE.PLAYING) {

        let guess = this.guesses[this.currentGuess];
        if((this.cursor == guess.length || guess[this.cursor] == "") && this.cursor > 0) {
          this.cursor--;
        }
        guess[this.cursor] = "";
        this.guesses[this.currentGuess] = guess.slice(0);
        this.wordError = false;
        if(!this.random){
          store.setCurrentGuesses(this.guesses, this.storeName);
        }
      }
    },
    wordGuessed() {
      this.state = STATE.WON;
      store.setState(STATE.WON, this.storeName);
      this.currentStreak = store.increaseCurrentStreak(this.storeName);
      this.highestStreak = store.getHighestStreak(this.storeName);
      this.showModal(true);
    },
    failedToGuess() {
      this.state = STATE.LOST;
      this.currentStreak = store.resetCurrentStreak(this.storeName);
      this.showModal(true);
    },
    showModal(delay) {
      let fac = delay === true ? 7 : 0;
      fac = fac * 350;
      let modal = new bootstrap.Modal(this.$refs.modal);
      setTimeout(() => {
        modal.show();
      }, fac);
    },
    scrollToNextGuess() {
      if(this.state == STATE.PLAYING) {
        let cont = this.$refs.guessesContainer;
        let guess = this.$refs['guess-' + this.currentGuess][0];
        let cOffset = cont.offsetHeight + cont.offsetTop
        let gOffset = guess.offsetHeight + guess.offsetTop;
        cont.scrollTo(0, gOffset - cOffset);
      }
    },
    getTodayDate(german) {
      let d = new Date();
      let yy = d.getFullYear(),
          mm = String(d.getMonth() + 1).padStart(2, "0"),
          dd = String(d.getDate()).padStart(2, "0");
      if(german) {
        return `${dd}.${mm}.${yy}`;
      }
      return `${yy}-${mm}-${dd}`
    },
    share() {
      let text = `WØRDBRØ | ${this.getTodayDate(true)} | ${this.state == STATE.LOST ? 'X' : this.currentGuess}/6\n`;
      guessesToEmoji(this.guesses, this.randomWord).forEach(emoji => {
        text += `${emoji}\n`
      });
      navigator.share({text: text});
    },
    setupWorder() {

    }
  },
  mounted() {
    this.setupWorder();
    this.language = store.getLanguage();
    this.scores = store.getScores(this.storeName);
    this.currentStreak = store.getCurrentStreak(this.storeName);
    this.highestStreak = store.getHighestStreak(this.storeName);
    if(!this.random) {
      this.getTodaysWord();
      let rnd = store.getRoundDate(this.storeName);
      let today = this.getTodayDate();
      if(rnd && rnd == today) {
        this.resumeGame();
      } else {
        this.resetGame();
      }
    } else {
      this.resetGame();
    }
  },
  components: {
    WordRow,
    VirtualKeyboard,
    Stats
  }
}
</script>

<style lang="scss" scoped>

</style>