<template>
  <div class="word-row">
    <div class="container-fluid">
      <div class="d-flex justify-space-between">
        <div class="border letter-box mx-1 my-2 rounded-2 border-2"
             :class="[classes[index], {'border-danger': error, 'border-light': !error, 'shake': error, 'flip-card': submitted}]"
             :key="'letter_' + index"
             :style="{'--animation-order': index + 1}"
             @click="setCursor(index)"
             v-for="(letter, index) in letters">
          <div class="reveal-color" :class="{'bg-black': cursor != index, 'bg-info': cursor == index}"></div>
          <div class="inner-word d-flex justify-content-center align-items-center">
            {{ letter }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkWord } from "../helper/checker";

export default {
  name: "WordRow",
  data: () => ({
    classes: []
  }),
  emits: ["setCursor"],
  props: {
    letters: {
      type: Array,
      default: () => ([])
    },
    submitted: {
      type: Boolean,
      default: false
    },
    word: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    },
    cursor: {
      type: Number,
      default: -1
    }
  },
  methods: {
    bgColor() {
      let res = checkWord(this.letters, this.word);
      this.classes = res.map((val) => {
        if(!this.submitted) return "bg-black";
        if(val == 1) {
          return "bg-success"
        } else if(val == 0) {
          return "bg-warning"
        } else if(val == -1) {
          return "bg-secondary"
        }
      })
    },
    setCursor(index) {
      if(this.submitted) return;
      this.$emit("setCursor", index);
    }
  },
  watch: {
    submitted: {
      handler(val) {
        this.bgColor();
      },
      immediate: true
    }
  }
}
</script>
<style scoped lang="scss">
.letter-box {
  color: white;
  height: 60px;
  width: 60px;
  position: relative;
}

.shake {
  animation: shake ease 200ms forwards;
}

.reveal-color {
  height: 100%;
  width: 100%;
  position: absolute;
}

.inner-word {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@keyframes shake {
  0% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.flip-card {
  animation-name: flipCard;
  animation-delay: calc(var(--animation-order) * 350ms - 350ms);
  animation-duration: 700ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  .reveal-color {
    animation: reveal linear 250ms forwards;
    animation-delay: calc((var(--animation-order) * 350ms));
  }
}

@keyframes flipCard {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(90deg);
  }
  50% {
    transform: rotateY(-90deg)
  }
  100% {
    transform: rotateY(0);
  }
}

@keyframes reveal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>