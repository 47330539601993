import { checkWord } from "./checker";

export default {
  guessesToEmoji
}

const GREEN = "🟩";
const YELLOW = "🟨"
const BLACK = "⬛️"

export function guessesToEmoji(guesses, word) {
  return guesses
          .filter(guess => guess[0] != "")
          .map(guess => guessToEmoji(guess, word))
}

function guessToEmoji(guess, word) {
  return checkWord(guess, word).map((val) => {
    if(val == 1) return GREEN;
    if(val == 0) return YELLOW;
    return BLACK;
  }).join(" ")
}