<template>
  <div class="card virtual-keyboard">
    <div class="card-body">
      <div class="d-flex justify-content-center mb-2" v-for="row in keyboards[this.lang]">
        <div class="col-1 btn mx-1 px-0 py-2 d-flex justify-content-center align-items-center font-monospace"
             :class="getBtnClass(key)"
             @click="$emit('click-key', key); vibrate(15)"
             v-for="key in row">
          {{ key }}
        </div>
      </div>
      <div class="d-flex justify-content-center mb-2">
        <div @click="$emit('click-del'); vibrate(15)" class="col-3 btn btn-outline-light mx-1 px-0 py-2 d-flex justify-content-center align-items-center font-monospace">
          <small>DEL</small>
        </div>
        <div @click="$emit('click-enter');" class="col-3 btn btn-outline-primary mx-1 px-0 py-2 d-flex justify-content-center align-items-center font-monospace">
          <small>ENTER</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "VirtualKeyboard",
    props: {
      exact: {
        type: Array,
        default: () => ([])
      },
      contained: {
        type: Array,
        default: () => ([])
      },
      wrong: {
        type: Array,
        default: () => ([])
      },
      lang: {
        type: String,
        default: "de"
      }
    },
    data: () => ({
      keyboards: {
        en: [
          ["Q", "W", "E", "R", "T", "Z", "U", "I", "O", "P"],
          ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
          ["Y", "X", "C", "V", "B", "N", "M"]
        ],
        de: [
          ["Q", "W", "E", "R", "T", "Z", "U", "I", "O", "P","Ü"],
          ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Ö", "Ä"],
          ["Y", "X", "C", "V", "B", "N", "M",]
        ]
      }
    }),
    methods: {
      getBtnClass(key) {
        if(this.exact.includes(key)) {
          return ["btn-success"]
        } else if(this.contained.includes(key)) {
          return ["btn-warning"]
        } else if(this.wrong.includes(key)) {
          return ["btn-secondary"]
        } else {
          return ["btn-primary"]
        }
      },
      handleKeyUp(event) {
        event.preventDefault();
        let key = event.key.toUpperCase();
        if(this.keyboards[this.lang].flat().includes(key)) {
          this.$emit("click-key", key);
        }
        if(key === "BACKSPACE") {
          this.$emit("click-del")
        }
        if(key === "ENTER") {
          this.$emit("click-enter")
        }
        return false;
      },
      handleKeyDown(event) {
        if(event.ctrlKey || event.altKey) {
          return;
        }
        event.preventDefault();
        return false;
      },
      vibrate(pattern) {
        window.navigator.vibrate(pattern);
      }
    },
    mounted() {
      window.addEventListener("keydown", this.handleKeyDown);
      window.addEventListener("keyup", this.handleKeyUp);
    },
    beforeUnmount() {
      window.removeEventListener("keyup", this.handleKeyUp);
      window.removeEventListener("keyup", this.handleKeyDown);
    }
  }
</script>

<style lang="scss">
.virtual-keyboard {
  width: 100%;
  max-width: 800px;
  font-size: 1em;
  .btn {
    @media (max-width: 768px) {
      font-size: 0.75em;
      &.mx-1 {
        margin-left: 0.225em!important;
        margin-right: 0.225em!important;
      }
    }
  }

  .card-body {
    background: var(--bs-gray-800);
  }
}
</style>