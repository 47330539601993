export function checkWord(letterArray, word) {
    let wordArray = word.split("");
    let results = new Array(word.length);
    letterArray.forEach((w, i) => {
        if(w == wordArray[i]) {
            results[i] = 1;
            wordArray[i] = "";
        }
    });
    letterArray.forEach((w,i) => {
        if(results[i] == 1) return;
        if(wordArray.includes(w)) {
            results[i] = 0;
            wordArray[wordArray.indexOf(w)] = "";
        } else {
            results[i] = -1;
        }
    })
    return results;
}