const HIGHEST = "wordbro.highest";
const CURRENT = "wordbro.current";
const CURRENT_GUESSES = "wordbro.guesses";
const CURRENT_GUESS = "wordbro.guess"
const ROUND_DATE = "wordbro.rounddate";
const SCORES = "wordbro.scores";
const LANG = "wordbro.language";
const STATE = "wordbro.state";

export default {
    getHighestStreak,
    getCurrentStreak,
    resetCurrentStreak,
    increaseCurrentStreak,
    setHighestStreak,
    setCurrentStreak,
    getCurrentGuesses,
    getCurrentGuess,
    getRoundDate,
    getScores,
    setCurrentGuesses,
    setCurrentGuess,
    setRoundDate,
    setScores,
    getLanguage,
    setLanguage,
    getState,
    setState
}

function set(key, value) {
    localStorage.setItem(key, value);
}

function get(key) {
    return localStorage.getItem(key);
}

export function getLanguage() {
    return get(LANG) || "de";
}

function setLanguage(lang) {
    set(LANG, lang);
    return getLanguage();
}

function getCurrentStreak(storeName) {
    let streak = parseInt(get(CURRENT+storeName));
    if(!streak) initCurrentStreak(storeName);
    return streak || 0;
}

function initCurrentStreak(storeName) {
    set(CURRENT+storeName, 0);
}

function resetCurrentStreak(storeName) {
    return setCurrentStreak(0, storeName);
}

function setCurrentStreak(streak, storeName) {
    set(CURRENT+storeName, streak);
    if(getCurrentStreak(storeName) > getHighestStreak(storeName)) {
        setHighestStreak(getCurrentStreak(storeName), storeName);
    }
    return getCurrentStreak(storeName);
}

function increaseCurrentStreak(storeName) {
    return setCurrentStreak(getCurrentStreak(storeName) + 1, storeName);
}

function getHighestStreak(storeName) {
    let streak = parseInt(get(HIGHEST+storeName));
    if(!streak) set(HIGHEST+storeName, 0);
    return streak || 0;
}

function setHighestStreak(streak, storeName) {
    set(HIGHEST+storeName, streak);
    return getHighestStreak(storeName);
}

function getCurrentGuess(storeName) {
    let guess = get(CURRENT_GUESS+storeName) || "0";
    return parseInt(guess);
}

function setCurrentGuess(guess, storeName) {
    set(CURRENT_GUESS+storeName, guess);
    return getCurrentGuess(storeName);
}

function getCurrentGuesses(storeName) {
    let guesses = get(CURRENT_GUESSES+storeName) || "[]";
    return JSON.parse(guesses);
}

function setCurrentGuesses(guesses, storeName) {
    set(CURRENT_GUESSES+storeName, JSON.stringify(guesses));
    return getCurrentGuesses(storeName);
}

function getRoundDate(storeName) {
    return get(ROUND_DATE+storeName);
}

function setRoundDate(date, storeName) {
    set(ROUND_DATE+storeName, date);
    return getRoundDate(storeName);
}

function getScores(storeName) {
    let scores = get(SCORES+storeName) || "{}"
    return JSON.parse(scores);
}

function setScores(scores, storeName) {
    set(SCORES+storeName, JSON.stringify(scores));
    return getScores(storeName);
}

function getState(storeName) {
    return parseInt(get(STATE+storeName) || "2")
}

function setState(state, storeName) {
    set(STATE+storeName, state);
    return getState(storeName);
}