<template>
  <div class="scores">
    <div class="score-row" v-for="r in rows" :key="'row_' + r">
      <div class="guess">{{ r }}</div>
      <div class="graph">
        <div class="inner-graph"
          :style="{width: (scores[r] / highestScore * 90) + '%'}"
        ></div>
        <div class="inner-count"
          :style="{width: (scores[r] / highestScore * 90) + '%'}"
        >
          {{ scores[r] || '0' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "../helper/store";
  export default {
    name: "Stats",
    data: () => ({
      scores: {},
      currentStreak: 0,
      highestStreak: 0,
      rows: ["1","2","3","4","5","6","X"]
    }),
    props: {
      storeName: {
        type: String,
        default: ""
      }
    },
    computed: {
      finishedRounds() {
        return Object.values(this.scores).reduce((total, s) => total + s, 0);
      },
      highestScore() {
        return Math.max.apply(null, Object.values(this.scores))
      }
    },
    mounted() {
      this.updateScores();
    },
    methods: {
      updateScores() {
        this.scores = store.getScores(this.storeName);
        this.currentStreak = store.getCurrentStreak(this.storeName);
        this.highestStreak = store.getHighestStreak(this.storeName);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .scores {
    width: 100%;
    color: white;
    background: rgba(0,0,0,0.2);
    padding: 15px 5px;
  }

  .score-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }

  .guess {
    width: 30px;
    text-align: center;
  }

  .graph {
    height: 25px;
    position: relative;
    flex: 1;
  }

  .inner-graph {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-width: 15px;
    background: #888888;
  }

  .inner-count {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    margin-left: 3px;
    text-align: center;
  }
</style>