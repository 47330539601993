<template>
  <div class="fullContainer" v-if="!updating">
    <nav class="navbar navbar-dark navbar-expand bg-black">
      <div class="container-fluid">
        <span class="navbar-brand">WØRDBRØ</span>
        <div class="dropdown">
          <button
          ref="dropdown"
          data-bs-toggle="dropdown"
          class="btn btn-primary dropdown-toggle">
            {{ gameMode }}
          </button>
          <div class="dropdown-menu" aria-labelledby="gameModeChanger">
            <a href="#"
               class="dropdown-item"
               @click="changeGame(5, false, '')"
            >5 - Daily</a>
            <a href="#"
              class="dropdown-item"
              @click="changeGame(5, true, '5rnd')"
            >5 - Random</a>
            <a href="#"
               class="dropdown-item"
               @click="changeGame(6, false, '6')"
            >6 - Daily</a>
            <a href="#"
               class="dropdown-item"
               @click="changeGame(6, true, '6rnd')"
            >6 - Random</a>
          </div>
        </div>
        <div></div>
        <div class="text-white" @click="$refs.gameComponent.showModal">[ i ]</div>
      </div>
    </nav>
    <component ref="gameComponent" :is="component" :wordLength="wordLength" :storeName="storeName" :random="random"></component>
  </div>
  <div v-else class="fullContainer centered">
    <div>
      <h1 v-if="!updated">
        Update wird geladen <span class="loadingAnim"></span>
      </h1>
      <h1 v-else>
        Update installiert. App wird neu geladen <span class="loadingAnim"></span>
      </h1>
    </div>
  </div>
</template>

<script>
import Wordler from './components/Wordler.vue'
import bootstrap from '@/assets/bootstrap.min.js';
export default {
  components: { Wordler },
  name: "Wordinski",
  data: () => ({
    updating: false,
    updated: false,
    wordLength: 5,
    storeName: "",
    random: false,
    component: "wordler",
    gameMode: "5 - Daily",
    dropdown: null
  }),
  methods: {
    changeGame(length, random, store) {
      this.component = "span"
      this.$nextTick(() => {
        this.wordLength = length;
        this.random = random;
        this.storeName = store;
        this.gameMode = length + (random ? " - Random" : " - Daily");
        this.component = "wordler";
      })
    }
  },
  mounted() {
    this.dropdown = new bootstrap.Dropdown(this.$refs.dropdown);
    document.addEventListener("service-worker-updating", () => {
      this.updating = true;
    });
    document.addEventListener("service-worker-updated", () => {
      this.updated = true;
    })
  }
}
</script>

<style lang="scss">
html, body {
  background: var(--bs-gray-900)!important;
  height: 100%;
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  background: var(--bs-gray-900);
  display: flex;
  flex-direction: column;
}

.fullContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: white;

  &.centered {
    align-items: center;
    justify-content: center;
  }
}

.vkboard {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.guesses {
  flex: 1;
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  .word-row {
    max-width: 500px;
  }
}

@keyframes loadingDots {
  0% {
    content: "\a0\a0\a0"
  }
  30% {
    content: ".\a0\a0"
  }
  60% {
    content: "..\a0"
  }
  90% {
    content: "..."
  }
}

.loadingAnim::after {
  content: "\a0\a0\a0";
  font-family: monospace;
  animation: loadingDots 3s linear infinite;
}
</style>
